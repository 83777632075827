var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"split-products-shipment"},[_c('el-row',{staticClass:"split-products-shipment-row"},[_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("SKU：")]),_c('span',[_vm._v(_vm._s(_vm.productData.sku))])]),_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("中文报关名：")]),_c('span',[_vm._v(_vm._s(_vm.productData.productCn))])]),_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("英文名：")]),_c('span',[_vm._v(_vm._s(_vm.productData.productEn))])]),_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("英文规格：")]),_c('span',[_vm._v(_vm._s(_vm.productData.productSpecs || '/'))])]),_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("中文规格：")]),_c('span',[_vm._v(_vm._s(_vm.productData.productSpecsCn || '/'))])]),_c('el-col',{attrs:{"span":8}},[_c('span',[_vm._v("总数量：")]),_c('span',[_vm._v(_vm._s(_vm.productData.totalNum))])])],1),_c('el-form',{ref:"productForm",attrs:{"model":_vm.productData,"label-width":"0","label-position":"left"}},[_c('el-table',{ref:"table",attrs:{"data":_vm.productData.productSubList,"align":"center","border":"","header-cell-style":{ 'text-align': 'center' },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"planShippingQuantity","label":"计划出运数量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.planShippingQuantity',"rules":[
                    {
                      required: true,
                      message: _vm.$t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      validator: function (rule, value, callback) {
                        if (!/^[1-9]\d*$/.test(value))  {
                          callback('请输入大于0的整数')
                        } else {
                          callback()
                        }
                      },
                      trigger: 'blur',
                    } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","disabled":!!scope.row.shippedFlag},model:{value:(scope.row.planShippingQuantity),callback:function ($$v) {_vm.$set(scope.row, "planShippingQuantity", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.planShippingQuantity"}})],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("计划出运数量")])])],2),_c('el-table-column',{attrs:{"prop":"overLoadingQuantity","label":"溢装数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.overLoadingQuantity',"rules":[
                {
                  validator: function (rule, value, callback) {
                    if(!value && value!== 0){
                      callback()
                    }else if (!/^(0|[1-9]\d*)$/.test(value))  {
                      callback('请输入整数')
                    }else{
                      callback()
                    }
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","disabled":!!scope.row.shippedFlag},model:{value:(scope.row.overLoadingQuantity),callback:function ($$v) {_vm.$set(scope.row, "overLoadingQuantity", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.overLoadingQuantity"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"caseNum","label":"箱数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.caseNum',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: function (rule, value, callback) {
                    if (!/^[1-9]\d*$/.test(value))  {
                      callback('请输入大于0的整数')
                    } else {
                      callback()
                    }
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","disabled":!!scope.row.shippedFlag},model:{value:(scope.row.caseNum),callback:function ($$v) {_vm.$set(scope.row, "caseNum", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.caseNum"}})],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("箱数")])])],2),_c('el-table-column',{attrs:{"prop":"grossWeight","label":"重量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.grossWeight',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: function (rule, value, callback) {
                    if (value <= 0)  {
                      callback('请输入大于0的整数')
                    } else {
                      callback()
                    }
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","disabled":!!scope.row.shippedFlag,"oninput":"if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"},model:{value:(scope.row.grossWeight),callback:function ($$v) {_vm.$set(scope.row, "grossWeight", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.grossWeight"}})],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("重量"),_c('span',[_vm._v("（kg）")])])])],2),_c('el-table-column',{attrs:{"prop":"volume","label":"体积"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.volume',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: function (rule, value, callback) {
                    if (value <= 0)  {
                      callback('请输入大于0的整数')
                    } else {
                      callback()
                    }
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","disabled":!!scope.row.shippedFlag,"oninput":"if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"},model:{value:(scope.row.volume),callback:function ($$v) {_vm.$set(scope.row, "volume", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.volume"}})],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("体积"),_c('span',[_vm._v("（m³）")])])])],2),_c('el-table-column',{attrs:{"width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.planShippingDate',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsSel'),
                  trigger: 'change',
                },
                {
                  validator: function (rule, value, callback) {
                    var customerDeliveryDate = new Date(scope.row.customerDeliveryDate);
                     if ( !isNaN(customerDeliveryDate.getTime()) &&new Date(value).getTime() > customerDeliveryDate.getTime()) {
                      callback('不能晚于客户交期');
                    } else {
                      callback();
                    }
                  },
                  trigger: 'change',
                }
              ]}},[_c('el-date-picker',{attrs:{"size":"small","type":"date","value-format":"yyyy-MM-dd","placeholder":"年/月/日","disabled":!!scope.row.shippedFlag},model:{value:(scope.row.planShippingDate),callback:function ($$v) {_vm.$set(scope.row, "planShippingDate", $$v)},expression:"scope.row.planShippingDate"}})],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("计划装运日期")])])],2),_c('el-table-column',{attrs:{"width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productSubList.' +
                scope.$index +
                '.shippingMethodId',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsSel'),
                  trigger: 'change',
                }
              ]}},[_c('el-select',{staticClass:"w100",attrs:{"placeholder":"请选择","disabled":!!scope.row.shippedFlag},on:{"change":function($event){return _vm.changeShipMethod(scope.row)}},model:{value:(scope.row.shippingMethodId),callback:function ($$v) {_vm.$set(scope.row, "shippingMethodId", $$v)},expression:"scope.row.shippingMethodId"}},_vm._l((_vm.shippingMethodList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.transportWayCn,"value":item.id}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("运输方式")])])],2),_c('el-table-column',{attrs:{"label":"客户交期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.customerDeliveryDate)+" "),(!!scope.row.firmFlag)?_c('el-tag',{attrs:{"type":"warning","size":"mini","effect":"dark"}},[_vm._v("Firm 交期")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.shippedFlag)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteRowFn(scope.row,scope.$index)}}},[_vm._v("删除")]):_vm._e()]}}])})],1),_c('div',{staticClass:"add-btn"},[_c('span',{on:{"click":_vm.addRowFn}},[_vm._v("添加一行")])]),_c('el-form-item',{attrs:{"label":"修改出运原因","prop":"reason","label-width":"120px","label-position":"top","rules":[
          {
            required: true,
            message: '请输入',
            trigger: 'blur',
          }
        ]}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 6 },"maxlength":"500","show-word-limit":""},model:{value:(_vm.productData.reason),callback:function ($$v) {_vm.$set(_vm.productData, "reason", $$v)},expression:"productData.reason"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }